import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Bug = ({data}) => {

  return (
    <Layout>
      <MiniLayout>

        <h2>bug (mini project #4)</h2>
        <p>Build a Chrome Extension that bugs an internet user who surfs the web. Alter the experience of ordinary web pages in unexpected ways.</p>
        <p>Make use of the techniques reviewed in Lab #5 (Content script, Browser Action with pop up page, message sending between scripts) and add your existing knowledge of JavaScript to create the experience. </p>
        <br></br>
        <a target="_blank" href="https://ch1n00.tumblr.com/">Here</a> is some inspiration by Chino Kim.
        <br></br>
        <p>Add a README.md file to your project that includes a short description and a screenshot of the way in which it alters websites.</p>
        <p>Compress the whole extension folder into a .zip file. In your projects folder should be one "bug-project" (with the code) folder and one "bug-project.zip" file.</p>
        <br></br>
        <p>when you are done:</p>
        <p>1. push your new project to your GitHub repositoy.</p>
        <p>2. in your GitHub, find the "bug-project.zip" file and copy the link.</p>
        <p>3. and link to the "bug-project.zip" file to your Room (the idea is that we can easily download your project as <a target="_blank" href="https://github.com/leoneckert/abc-f20/tree/master/labs/lab-05">exemplified in Lab 5's code</a>).</p>
      </MiniLayout>
    </Layout>
  )
}



export default Bug
